
<template>
  <div class="Fince_Cw_B">
    <div class="tab_Top">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect">
        <el-menu-item index="1">工资单</el-menu-item>
        <el-menu-item index="2">账单核对</el-menu-item>
        <el-menu-item index="3">资金管理</el-menu-item>
        <el-menu-item index="4">发票管理</el-menu-item>
      </el-menu>
      <div class="Men_1">
        说明：设置考勤部门与人员、考勤班次、考勤方式（Wi-Fi）等相关规则，点击下方添加考勤进入按步骤设置即可。
      </div>
    </div>
    <div>
      <div class="Tables" style="padding-bottom:18px">
        <div class="Trys" style="margin-right:300px">
          <div class="uiius" style="margin-top:8px">核对账户：</div>
          <div class="uiius sddsdd">微信支付（默认）</div>
          <div class="uiius" style="margin-left:30px;margin-top:6px;cursor: pointer;">支付宝（跳转到支付宝平台）</div>
        </div>
   
        <div class="Trys Tiii" style="margin-right:480px;margin-top:15px">
          <div class="uiius" style="margin: 6px 0 0 0px;">核定月份：</div>
          <el-date-picker v-model="Months11" type="month" placeholder="选择月份" :clearable="false"></el-date-picker>
          <span style="margin: 0 5px 0 15px;">至</span>
          <el-date-picker v-model="Months22" type="month" placeholder="选择月份" :clearable="false"></el-date-picker>
          <el-button style="margin-left:15px" size="small" type="primary" @click="Btn_chaxun(1)">查询</el-button>
          <el-button size="small" @click="Btn_chongzhi">重置</el-button>
        </div>
      </div>
      <div class="Tables">
      <div class="Tab_T" @click="Add_kq(4)" style="border: 1px solid #D9D9D9;background: #fff;color: #595959;"><img class="Img_ti" src="../../assets/img/xiazai.png" alt="">下载表格</div>
      <div class="el_seso"><i class="el-icon-search" @click="inpu_sech(1)"></i></div>
      <div class="dsds">
        <el-input v-model="input_seach" placeholder="请输入内容" @keyup.enter.native="inpu_sech(1)"></el-input>
      </div>
      <div >
        <el-table
          :data="tableData"
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="handleSelectionChange">
          <el-table-column  prop="id" label="ID"  width="55" align="center"></el-table-column>
          <el-table-column align="center" prop="pay_time" label="交易时间" width="230" style="padding-left:20px"></el-table-column>
          <el-table-column align="center" prop="out_trade_no" label="商户订单号" width="240"></el-table-column>
          <el-table-column align="center" prop="transaction_id" label="微信支付单号" width="300"></el-table-column>
          <el-table-column align="center" prop="trade_type" label="支付场景" width="200"></el-table-column>
          <el-table-column align="center" prop="trade_state" label="交易状态" width="180"></el-table-column>
          <el-table-column align="center" prop="total_amount" label="订单金额（元）" width="180"></el-table-column>
          <el-table-column align="center" label="操作">
            <template #default="scope">
              <div class="el_butto" style="margin-right:15px">
                  <el-button
                  v-if="scope.row.back_status == 10"
                  type="text"
                  @click="handSub(scope.row)"
                  >确认</el-button>
                  <el-button v-if="scope.row.back_status != 10"
                  type="text"
                  >已确认</el-button>
              </div>
              <div class="el_butto">
                  <el-button
                  type="text"
                  @click="handEdit(scope.row)"
                  >编辑</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            background
            @current-change="handChange"
            :current-page="currentPage1"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>

      </div>
    </div>


    <!--发起转账-->
    <el-dialog title="发起转账" v-model="editVisible" width="33%"> 
      <div class="Terer">
        <div class="Tatt_a">
          <span :class="actit3 == 1 ? 'actyty' : ''" @click="Wxhb(1)">微信红包</span>
          <span :class="actit3 == 2 ? 'actyty' : ''" @click="Wxhb(2)">微信零钱</span>
          <span :class="actit3 == 3 ? 'actyty' : ''" @click="Wxhb(3)">微信转卡</span>
        </div>
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>收款方户名：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.realname" placeholder="请填写持卡人姓名" class="El_ii"></el-input>
            </div>
        </div>
        <div class="Rig_D Rl_nput" v-if="actit != 3">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>收款方openid：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.openid" placeholder="请填写收款人openid" class="El_ii"></el-input>
            </div>
        </div>
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>收款方账号：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.bank_card" placeholder="只支持向个人银行账户付款" class="El_ii"></el-input>
            </div>
        </div>
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>收款开户行：
            </div>
            <div class="Rttyyy">
              <el-select  v-model="Crojj.bank_open" placeholder="请选择正确的开户行信息" @change="Chang_ses">
                  <el-option  v-for="item in bankList" :key="item" :label="item" :value="item" ></el-option>
              </el-select>
              <!-- <el-input v-model="Crojj.bank_open" placeholder="请填写正确的开户行信息" class="El_ii"></el-input> -->
            </div>
        </div>
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>打款金额：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.money" placeholder="请填写需要付款的金额" class="El_ii"></el-input>
            </div>
        </div>
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>打款事由：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.reason" placeholder="请填写打款事由" class="El_ii"></el-input>
            </div>
        </div>
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>密码：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.password" placeholder="请输入密码" class="El_ii"></el-input>
            </div>
        </div>
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>备注：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.remark" placeholder="请选择员工" class="El_ii"></el-input>
            </div>
        </div>
         <div class="Dityo">
          <div class="dialog-footer">
            <el-button @click="editVisible = false">取 消</el-button>
            <el-button type="primary" @click="BTN_Hb" style="margin-left:15px">保存设置</el-button>
          </div>
        </div> 
      </div>

    </el-dialog>
    <!--发起转账-->

    <!-- 薪资确认 -->
    <el-dialog title="薪资确认" v-model="editVisible2" width="30%">
        <div>
          <div class="Rig_Ddd" style="margin-bottom:8px;width:80px"> 
            <span style="color:#FF4D4F">* </span>确认选项
          </div>
          <el-radio v-model="radio" label="20">无问题（默认）</el-radio>
          <el-radio v-model="radio" label="30">有异议</el-radio>
        </div>

        <div class="Ruuu">问题描述：（选填）</div>
        <div class="Ryyy">
          <el-input type="textarea" placeholder="您可以将问题详细的描述在这里，财务人员会及时和您沟通" v-model="ED_textarea">
          </el-input>
        </div>

      <div class="Rtty" style="overflow:hidden">
        <div class="BCSZ" @click="Btn_tj">提交</div>
        <div class="BCSZ" style="background:#fff;color:#595959;border: 1px solid #D9D9D9;margin-right:20px" @click="editVisible2 = false">取消</div>
      </div>
    </el-dialog>
    <!-- 薪资确认 -->

    <!--添加员工-->
    <div ref="rightPanel" v-if="show1" class="rightPanel-container show">
          <div class="rightPanel-background"/>
          <div style="height:1100px;width: 680px;position: fixed;top: 0;right: 0;z-index:999;" >
            <el-scrollbar style="height:100%">
              <div class="rightPanel" style="height:1100px">
                <div class="Rig_1">查看转账详情<i class="el-icon-close" @click="Close" style="float:right;padding:20px 50px;cursor: pointer;"></i></div>
                <div style="margin:0 32px" class="sadasd">
                  <table width="600" border="1" cellspacing="0" cellpadding="0"  border-collapse:collapse  bordercolor="#D9D9D9"> 
                  <tr> 
                    <td width="105">收款人姓名</td> 
                    <td width="181">{{el_table_Data.realname}}</td> 
                    <td width="112">收款方openid</td> 
                    <td width="112">{{el_table_Data.openid}}</td> 
                  </tr> 
                  <tr> 
                    <td>打款事由</td> 
                    <td>{{el_table_Data.reason}}</td> 
                    <td>打款金额</td> 
                    <td>{{el_table_Data.money}}</td> 
                  </tr> 
                  <tr> 
                    <td>转账类型</td> 
                    <td>{{el_table_Data.pay_type_text}}</td> 
                    <td>转账时间</td> 
                    <td>{{el_table_Data.pay_type_text}}</td> 
                  </tr> 
                  <tr> 
                    <td>转账状态</td> 
                    <td>{{el_table_Data.pay_status_text}}</td> 
                    <td>操作人</td> 
                    <td>{{el_table_Data_realname}}</td> 
                  </tr> 
                  </table>  
                  <div>
                    <div style="font-size: 14px;font-weight: bold;color: #262626;margin:20px 0">付款备注信息：</div>
                    <div class="uyuyyu">
                      {{el_table_Data.remark}}
                    </div>
                  </div>
                </div>
             
                </div>
            </el-scrollbar>
          </div>
    </div>
    <!--添加员工-->

  </div>
</template>

<script>
import axios from 'axios'  
export default {
  name: "basetable",
  
  data() {
    return {
      actit:0,
      actit2:0,
      actit3:1,
      P_actitA_1:0,
      P_actitA_2:0,
      P_actitA_3:0,
      MMD:[{id:1,name:"待批复"},{id:2,name:"同意开票"},{id:3,name:"拒绝申请"},{id:4,name:"已开票"}],
      Fond_tag:null,
      Kaipiao_time:'',
      Kaipiao_price:'',
      Kaipiao_peop:'',

      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},

      Row_Eurl:[], //图片上传成功存储

      full_avatar:[],
      full_avatar2:[],

      Months11:'',
      Months111:'',
      Months1:'',
      Months222:'',
      Months22:'',
      Months2:'',
      fapiao_id:0,

      activeIndex: "2",

      ionini:'',
      staff_id:0,
      ED_textarea:'',
      el_table_Data:[],
      el_table_Data_order:{},
      el_table_Data_row:{},
      el_table_Data_user:{},
      el_table_Data_address:null,
      bankList:[],
      el_table_Data_realname:'',


      Mooo1:'',
      Mooo2:'',
      StaffList:'',

      StaFFList:[],
      
      input_seach:'',
      Bumen_Xinj1:null,

      People_jj1:'',
      People_jj2:'',
      People_jj3:'',
      People_jj4:'',

      People_jj_y_day:'',
      People_jj_s_day:'',
      People_jj_s_jb:'',

      People_jj_s_j1:'',
      People_jj_s_j2:'',
      People_jj_s_j3:'',
      People_jj_s_j4:'',
      People_jj_s_j5:'',
      People_jj_s_j6:'',
      People_jj_s_j7:'',
      People_jj_s_j8:'',
      People_jj_s_j9:'',
      People_jj_s_j10:'',
      People_jj_s_j11:'',
      People_jj_s_j12:'',

      
      DepartmentList:[],
      StaffList:[],
      Parents:[],

      show1: false,
      show2: false,

      XX:'xin',
      department_id:0,

      editVisible:false,
      editVisible1:false,
      editVisible2:false,
      input: "",
      
      tableData: [],
      tableData2: [],
      tableData3:[],
      total:0,
      total2:0,
      total3:0,
      currentPage1:0,
      currentPage2:0,
      currentPage3:0,
      multipleSelection: [],
      selection_arr2:[],     // 资金管理选中
      selection_arr3:[],     // 资金管理选中
      id: -1,
      Row:{},
      Crojj:{
        pay_type: "redpack", //转账类型，示例：redpack=红包，transfer=零钱，paybank=银行卡
        password: "123456789", //密码，示例：123456789
        realname: "张三", //收款人姓名
        // openid: "asdadsdsadasdsd", //收款人openid，类型为红包和零钱时必填
        bank_card: "50000000000000000000", //银行卡号-类型为银行卡时必填
        bank_open: "工商银行", //开户行-类型为银行卡时必填
        money: 100, //转账金额
        reason: "采购", //打款事由
        remark: "" //备注
      }
    };
  },
  mounted() {
    var that = this
    that.AAAAA()
    that.BBBBB()
    that.StaFFLis()

    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
  },
  methods: {
    Close() {
      this.dialogVisible3 = false;
      this.dialogVisible4 = false;
      this.dialogVisible5 = false;
      this.dialogVisible6 = false;
      this.dialogVisible7 = false;
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
    },
    Add_kq(e) {  //
        var that = this
        if (e == 1) {
          that.editVisible = true
          that.Crojj = {}
          that.Crojj.pay_type = 'redpack'
        } else if (e == 2) {
          that.show1 = true
        }
    },
    AUOP(e) {
      console.log(e)
      var that = this
      that.actit = e
    },
    AUOP2(e) {
      var that = this
      that.actit2 = e
    },
    P_AUOP1(e) {
      var that = this
      that.P_actitA_1 = e      
      
    },
    P_AUOP2(e) {
      var that = this
      that.P_actitA_2 = e      
    },
    P_AUOP3(e) {
      var that = this
      that.P_actitA_3 = e     
    },
    Wxhb(e) {         //微信转账类型切换
      var that = this
      that.actit3 = e
      if(e == 1) {
        that.Crojj.pay_type = 'redpack'
      }else if (e == 2) {
        that.Crojj.pay_type = 'transfer'
      }else if (e == 3) {
        that.Crojj.pay_type = 'paybank'
      }
    },

    UP_Success(e) {  //图片上传成功
      var that = this
      console.log(e)
      that.Row_Eurl.push(e.data.url)
      that.aTY = 0
      console.log(that.Row_Eurl)
    },
    // Up_chang(file,fileList) {
    //   var that = this
    //   for (let i = 0; i < fileList.length; i++) {
    //     const element = fileList[i];
    //     console.log(element)
    //   }
      
    // },
    handleRemove(file, fileList) {
      var that = this
      that.Row_Eurl = []
        console.log(fileList)
        for (let i = 0; i < fileList.length; i++) {
          const element = fileList[i];
          that.Row_Eurl.push(element.response.data.url)
         
        }
        console.log(that.Row_Eurl)
    },
    handlePictureCardPreview(file) {   //获取图片
      var that = this
      that.full_image = file.url;
    },

    handleSelect(key, keyPath) {               ////////////////////////////////////
      var that = this
      console.log(key, keyPath);
      that.input_seach = ''
      that.actit = 0
      that.actit2 = 0
      that.actit3 = 1
      that.Months11 = ''
      that.Months1 = ''
      that.Months22 = ''
      that.Months2 = ''
      if (key == 1) {
        that.$router.push('/Workbench/Fince_cw')
      } else if(key == 2){
        that.$router.push('/Workbench/Fince_cw_B')
      } else if (key == 3) {
        that.$router.push('/Workbench/Fince_cw_C')
      } else if (key == 4) {
        that.$router.push('/Workbench/Fince_cw_D')
      }
    },
    inpu_sech(e) {   // 搜索材料
        var that = this
        if (e == 1) {
          that.currentPage1 = 0,
          axios({ 
            method: 'post',
            url: '/admin/finance/bill/index',    
            data: {
              page: 1,
              limit:10,
              filter:{
                  keyword:that.input_seach
                }
            },
          }).then(function (res) {
              if (res.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                  that.total = res.data.data.total
                  that.tableData = res.data.data.rows
              }

          })
        } else if (e == 2) {
          that.currentPage2 = 0,
          axios({ 
            method: 'post',
            url: '/admin/finance/withdraw/index',    
            data: {
              page: 1,
              offset:0,
              limit:10,
              filter:{
                  keyword:that.input_seach
                }
            },
          }).then(function (res) {
              if (res.code == 401) {
                 that.$router.push('/Login');
              } else {
                that.total2 = res.data.data.total
                that.tableData2 = res.data.data.rows
              }

          })
        }
    }, 

    BTN_Hb() {      //发起转账
     var that = this
        axios({   
          method: 'post',
          url: '/admin/finance/withdraw/payment',    
          data: {
            row:that.Crojj
          },
        }).then(function (res) {
          console.log(res)
          if (res.data.code == 0) {
             that.$message.warning(res.data.msg);
          }else {
             that.$message.success(res.data.msg);
            that.editVisible = false
            that.BBBBB()
          }
        })         
    },
    Chang_ses() {
      var that = this
      console.log(that.Crojj.bank_open)
    },

    handSub(e) {
      var that = this
      console.log(e)
      that.editVisible2 = true
      that.staff_id = e.id
    },
    handDelete2(e) {
        var that = this
        var arr  = []
        arr.push(e.id)
        that.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        }).then(() => {
        axios({
            method: 'delete',
            url: '/admin/finance/withdraw/del',
            data: {
              ids: arr
            },
          }).then(function (res) {
            that.$message.success(res.data.msg);
            axios({ 
              method: 'post',
              url: '/admin/finance/withdraw/index',    
              data: {
                page: that.currentPage2,
                offset:0,
                limit:10,
              },
            }).then(function (res) {
                if (res.code == 401) {
                  that.$router.push('/Login');
                } else {
                  that.total2 = res.data.data.total
                  that.tableData2 = res.data.data.rows
                }

            })
          })                 
        })
    },

    // 多选操作
    handleSelectionChange(val) {
        this.multipleSelection = val;
    },
    Btn_tj() {          //确认有无异议提交
      var that = this
        axios({ 
          method: 'post',
          url: '/admin/finance/salary/confirm',    
          data: {
            id:that.staff_id,
            status:that.radio,
            msg:that.ED_textarea
          },
        }).then(function (res) {
          console.log(res)
          if (res.data.code == 0) {
             that.$message.warning(res.data.msg);
             that.editVisible2 = false
          }else {
             that.$message.success(res.data.msg);
            that.editVisible2 = false
            that.AAAAA()
          }
        })      
    },
    Btn_chongzhi() {    //重置
      var that = this
      that.Months11 = '',
      that.Months22 = '',
      that.Mooo1 = '',
      that.Mooo2 = ''
      that.StaffList = [],
      that.input_seach = ''
      that.actit = 0
      that.actit2 = 0
      that.Months1 = ''
      that.Months2 = ''
    },

    Btn_chaxun(e) {        //查询
        var that = this
        var time = ''
        var time2 = ''
        if (e == 1) {
          if (that.Months11 != '' ) {
            var date = new Date(that.Months11)
            var y = date.getFullYear()
            var m = date.getMonth() + 1
            m = m < 10 ? ('0' + m) : m
            var d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            time = y + '-' + m + '-' + d
          }
          if (that.Months22 != '') {
            var date2 = new Date(that.Months22)
            var y2 = date2.getFullYear()
            var m2 = date2.getMonth() + 1
            m2 = m2 < 10 ? ('0' + m2) : m2
            var d2 = date2.getDate()
            d2 = d2 < 10 ? ('0' + d2) : d2
            time2 = y2 + '-' + m2 + '-' + d2
          }          
          that.currentPage1 = 0,
          axios({ 
            method: 'post',
            url: '/admin/finance/bill/index',    
            data: {
              page: 1,
              limit:10,
              filter:{
                month: {
                  min: time, //最小
                  max: time2 //最大
                  }, //核对月份
                  keyword:that.input_seach
                }
            },
          }).then(function (res) {
              if (res.code == 0) {
                that.$message.warning(res.data.msg);
              } else {
                  that.total = res.data.data.total
                  that.tableData = res.data.data.rows
              }

          })          
        } else if (e == 2) {
          if (that.Months1 != '' ) {
            var date = new Date(that.Months1)
            
            var y = date.getFullYear()
            var m = date.getMonth() + 1
            m = m < 10 ? ('0' + m) : m
            var d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            time = y + '-' + m + '-' + d
          }
          if (that.Months2 != '') {
            var date2 = new Date(that.Months2)
            var y2 = date2.getFullYear()
            var m2 = date2.getMonth() + 1
            m2 = m2 < 10 ? ('0' + m2) : m2
            var d2 = date2.getDate()
            d2 = d2 < 10 ? ('0' + d2) : d2
            time2 = y2 + '-' + m2 + '-' + d2
          }
          axios({
            method: 'post',
            url: '/admin/finance/withdraw/index',    
            data: {
              page: 1,
              offset:0,
              limit:10,
              filter:{
                pay_type: that.actit,
                pay_status: that.actit2,
                time:{
                  min:time,
                  max:time2,
                },
                keyword:that.input_seach
              }
            },
          }).then(function (res) {
              if (res.code == 401) {
                 that.$router.push('/Login');
              } else {
                that.total2 = res.data.data.total
                that.tableData2 = res.data.data.rows
              }

          })
        }
    },
    handChange(val) {     // 分页
      var that = this
      that.currentPage1 = val
        axios({ 
          method: 'post',
          url: '/admin/finance/bill/index',    
          data: {
            page: val,
            limit:10,
            filter:{
                keyword:that.input_seach
            }
          },
        }).then(function (res) {
            if (res.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
                that.total = res.data.data.total
                that.tableData = res.data.data.rows
            }

        })
    },
    handChange2(val) {     // 分页2
      var that = this
      that.currentPage2 = val
        axios({ 
          method: 'post',
          url: '/admin/finance/withdraw/index',    
          data: {
            page: val,
            limit:10,
            filter:{
                keyword:that.input_seach
            }
          },
        }).then(function (res) {
            if (res.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
                that.total2 = res.data.data.total
                that.tableData2 = res.data.data.rows
            }

        })
    },
 
    AAAAA() {
          var that = this
          axios({ 
            method: 'post',
            url: '/admin/finance/bill/index',    
            data: {
              page: 1,
              offset:0,
              limit:10,
            },
          }).then(function (res) {
              if (res.code == 401) {
                 that.$router.push('/Login');
              } else {
                that.total = res.data.data.total
                that.tableData = res.data.data.rows
              }

          })
    },
    BBBBB() {
          var that = this
          axios({ 
            method: 'post',
            url: '/admin/finance/withdraw/index',    
            data: {
              page: 1,
              offset:0,
              limit:10,
            },
          }).then(function (res) {
              if (res.code == 401) {
                 that.$router.push('/Login');
              } else {
                that.total2 = res.data.data.total
                that.tableData2 = res.data.data.rows
                that.bankList = res.data.data.bankList
              }

          })
    },

    StaFFLis() {      //全部员工列表
      var that = this
      axios.get('/admin/ajax/staffList',{   // 通讯录选择
        }).then(function (res) {
          if (res.code == 0) {
            alert('请求失败');  
          } else{ 
                that.StaFFList = res.data.data
          }
      })      
    },

    handEdit2(t) {      //资金管理查看
      var that = this
      axios.get('/admin/finance/withdraw/read',{   // 模块
        params:{
            id:t.id
        }
        }).then(function (res) {
          that.el_table_Data = res.data.data.rows
          that.el_table_Data_realname = res.data.data.row.staff.realname
      })
      that.show1 = true

    },

    select_change2(sel) {   // 资金管理勾选
      var that = this
      var searr = []
      for (let i = 0; i < sel.length; i++) {
        const ele = sel[i].id;
        searr.push(ele)
      }
      that.selection_arr2 = searr

    },
    select_change3(sel) {   // 发票勾选
      var that = this
      var searr = []
      for (let i = 0; i < sel.length; i++) {
        const ele = sel[i].id;
        searr.push(ele)
      }
      that.selection_arr3 = searr

    },
    Staff_del3(e) {   // 批量删除
      var that = this
        that.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        }).then(() => {
          if (e == 2) {
            var arr = []
            for (let i = 0; i < that.selection_arr2.length; i++) {
              const element = that.selection_arr2[i];
              arr.push(element)
            }
            axios.delete('/admin/finance/withdraw/del',{   // 模块
              params:{
                    ids: arr
              }
            }).then(function (res) {
            if (res.code == 0) {
                  that.$message.warning(res.data.msg);
            } else{ 
                that.$message.success(res.data.msg);
                axios({ 
                  method: 'post',
                  url: '/admin/finance/withdraw/index',    
                  data: {
                    page: that.currentPage2,
                    offset:0,
                    limit:10,
                  },
                }).then(function (res) {
                    if (res.code == 401) {
                      that.$router.push('/Login');
                    } else {
                      that.total2 = res.data.data.total
                      that.tableData2 = res.data.data.rows
                      that.bankList = res.data.data.bankList
                    }

                })
            }
          })
          }

        })
    },
  
  },
};
</script>

<style scoped>

.Fince_Cw_B .Tab_T i {
  margin-right: 5px;
}
.Fince_Cw_B .Tables .dsds {
  float: right;
}
.Fince_Cw_B .Tables .dsds .el-input__inner {
  width: 239px;
  height: 32px;
  float: right;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.Fince_Cw_B .Tables .dsds .el-input {
  float: right;
  width: 30%;
}
.Fince_Cw_B .el_seso {
  float: right;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 0 4px 4px 0;
  border-left: 0;
  cursor: pointer;
}
.Fince_Cw_B .Tables .el_seso i {
  color: #8c8c8c;
}
.Fince_Cw_B .el-pagination {
    float: right;
    margin-top: 28px;
}
.Fince_Cw_B .el_butto {
    display: inline-block;
}
.Fince_Cw_B .el_butto .el-button {
    width: 68px;
    height: 32px;
    background: #1890FF;
    border-radius: 3px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #FFFFFF;
}
.Fince_Cw_B .rere .el-button {
    background: #FF524D;
    margin-left: 10px;
}
.Fince_Cw_B .show .rightPanel-background {
    z-index: 200;
    opacity: 1;
    width: 100%;
    height: 100%;
}
.Fince_Cw_B .rightPanel-background{
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    -webkit-transition: opacity 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    transition: opacity 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
    background: rgba(0, 0, 0, 0.3);
    z-index: -1;
}
.Fince_Cw_B .rightPanel {
    width: 100%;
    max-width: 839px;
    height: 1400px;
    top: 0;
    right: 0;
    -webkit-box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 5%);
    box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 5%);
    -webkit-transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
    transition: all 0.25s cubic-bezier(0.7, 0.3, 0.1, 1);
    -webkit-transform: translate(0%);
    transform: translate(0%);
    background: #fff;
    z-index: 400;
}
.Fince_Cw_B .Rig_1 {
    width: 100%;
    height: 60px;
    line-height: 68px;
    padding-left: 29px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #000000;
    margin-bottom: 20px;
    background: none;
    box-shadow: none;
}
.Fince_Cw_B .Rig_A {
    margin-left: 24px;
}
.Fince_Cw_B .Rig_D {
    margin-bottom: 20px;
    overflow: hidden;
}
.Fince_Cw_B .Rig_Ddd {
    font-size: 14px;
     
    font-weight: 400;
    color: #333333;
    width: 123px;
    float: left;
    text-align: left;
    /* margin-top: 11px; */
    margin-right: 13px;
}
.Fince_Cw_B .rightPanel .el-input {
    width: 343px;
    /* height: 32px; */
    background: #FFFFFF;
    border-radius: 3px;
}
.Fince_Cw_B .BCSZ {
    width: 88px;
    text-align: center;
    line-height: 32px;
    height: 32px;
    background: #0081FF;
    border-radius: 3px;
    font-size: 14px;
     
    font-weight: 400;
    color: #FFFFFF;
    margin: 40px 0px 0;
    float: right;
    border: 1px solid #0081FF;
    cursor: pointer;
}

.Fince_Cw_B .el-input__inner, .el-textarea__inner {
    height: 32px;
    line-height: 32px;
    width: 343px;
}
.Fince_Cw_B .el-radio {
    color: #606266;
    font-weight: 500;
    line-height: 1;
    position: relative;
    cursor: pointer;
    display: inline-block;
    white-space: nowrap;
    outline: none;
    font-size: 14px;
    margin-right: 18px;
}
.Fince_Cw_B .Riot {
  overflow: hidden;
  clear: both;
  margin-top: 20px;
}
.Fince_Cw_B .Riot div {
  float: left;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #595959;
}
.Fince_Cw_B .Rig_y div:last-child {
  font-size: 14px;
   
  font-weight: 400;
  color: #8C8C8C;
  margin-left: 14px;
  margin-top: 8px;
}
.Fince_Cw_B .Rig_y div:first-child{
  width: 80px;
  height: 32px;
  background: #FFFFFF;
  border: 1px solid #0081FF;
  border-radius: 3px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
   
  font-weight: 400;
  color: #0081FF;
}
.Fince_Cw_B .Rig_y div {
  float: left;
}
.Fince_Cw_B .block .el-input__inner, .el-textarea__inner {
  width: 100%!important;
}
.Fince_Cw_B .Tables .el-select, .Tables .el-input, .el-input__icon {
  line-height: 32px;
}
.Fince_Cw_B .Trt {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #262626;
}
.Fince_Cw_B .Trys {
  float: left;
}
.Fince_Cw_B .Trys .el-input {
  width: 275px;
  margin-left: 12px;
}
.Fince_Cw_B .Trys .el-input__inner, .el-textarea__inner {
  width: 275px;
}

.Fince_Cw_B .T1 .el-button {
    border: none;
    padding: 0;
    float: left;
    margin-top: 21px;
    margin-right: 10px;
}
.Fince_Cw_B .Topo .el-form-item__content .el-input__inner {
  width: 155px!important;
}

.Fince_Cw_B .Tab_y {
    display: inline-block;
    height: 32px;
    line-height: 32px;
    padding: 0 12px;
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    color: #595959;

    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #595959;
    margin-right: 10px;
}
.Fince_Cw_B .Tab_y i {
  margin-right: 5px;
}
.Fince_Cw_B .Bloo .el-form-item__label{
  float: none;
  line-height: 28px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #595959;
}
.Fince_Cw_B .Bloo .Trys .el-input__inner, .el-textarea__inner {
  height: 90px;
  resize:none
}
.Fince_Cw_B .Bloo .el-dialog__body {
  padding-top: 6px;
}
.Fince_Cw_B .Bloo .el-form-item {
  margin-bottom: 12px;
}
.Fince_Cw_B .shen_c {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #262626;
  margin: 30px 0 10px;
} 
.Fince_Cw_B .shen_b {
  width: 552px;
  height: 79px;
  background: #FAFAFA;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  box-sizing: border-box;
  padding: 20px 16px;
}
.Fince_Cw_B .shen_d .el-image{
  border-radius: 5px;
}
.Fince_Cw_B .Table_2 {
  width: 95.5%;
  height: 685px;
  background: #FFFFFF;
  border-radius: 4px;
  margin: 24px;
  padding: 24px;
}
/* .el-table--border td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
  border: none;
} */
/* .el-table td, .el-table th.is-leaf {
  border: none;
} */
.Fince_Cw_B .el-table--border td:first-child .cell, .el-table--border th:first-child .cell {
  padding-left: 20px;
}
.Fince_Cw_B .el_butto .el-button {
  min-height: 32px;
  line-height: 8px;
}
.Fince_Cw_B .C_shenpi {
  width: 144px;
  height: 32px;
  background: #1890FF;
  border-radius: 4px;
  text-align: center;
  line-height: 32px;
  color: #FFFFFF;
}
.Fince_Cw_B .c_ss1 {
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #8C8C8C;
  margin-top: 28px;
  margin-bottom: 5px;
}
.Fince_Cw_B .c_tt2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.Fince_Cw_B .c_t3 {
  width: 270px;
  height: 58px;
  background: #F5F5F5;
  border-radius: 4px;
  margin-bottom: 12px;
}
.Fince_Cw_B .c_t3 .el-image {
  float: left;
  border-radius: 50%;
  margin: 12px;
}
.Fince_Cw_B .c_t4 {
  float: left;
  margin-top: 10px;
}
.Fince_Cw_B .c_t4 div:first-child {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #262626;
}
.Fince_Cw_B .c_t4 div:last-child {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #8C8C8C;
}
.Fince_Cw_B .rightPanel .el-input {
  width: 250px;
}
.Fince_Cw_B .el-input__inner, .el-textarea__inner {
  width: 250px;
  height: 32px;
  line-height: 32px;
}
.Fince_Cw_B .el-input__suffix, .el-input__icon {
    line-height: 32px;
}
.Fince_Cw_B .laushd {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #262626;
  margin-top: 18px;
  margin-bottom: 15px;
}
.Fince_Cw_B .Rifg .el-input__inner, .el-textarea__inner {
  width: 472px!important;
  height: 108px;
  background: #FFFFFF;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
}
.Fince_Cw_B .xxiii {
  height: 20px;
}
.Fince_Cw_B .Nasa .Rtty {
  width: 70%;
  float: left;
}
.Fince_Cw_B .Riii {
  width: 100px;
  text-align: right;
}
.Fince_Cw_B .Xiui .el-input__inner, .el-textarea__inner {
  width: 490px!important;
}
.Fince_Cw_B .toytioy .rightPanel{
  max-width: 656px;
}
.Fince_Cw_B .Ffyy {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #262626;
  margin: 38px 0 8px;
}
.Fince_Cw_B .Ffii {
  width: 607px;
  height: 84px;
  background: #FAFAFA;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #595959;
  line-height: 26px;
  padding: 16px 20px;
  box-sizing: border-box;
}
.Fince_Cw_B .xinnnad {
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #262626;
  margin-bottom: 24px ;
}


.Fince_Cw_B .Tabless .el_seso, .Info_M .el_seso,.Info_M .hhhh .el_seso {
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 32px;
    border: 1px solid #d9d9d9;
    border-radius: 0 4px 4px 0;
    border-left: 0;
    cursor: pointer;
float: left;
    margin-top: 4px;
}
.Fince_Cw_B .hhhh .el-input__inner,.Info_M .el-textarea__inner {
  width: 370px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.Fince_Cw_B .Trtfd {
    border: 1px solid #E8E9EC;
    border-left: 0;
    display: flex;
    
}
.Fince_Cw_B .Trtfd .Tytt {
    flex: 0 0 50%;
    border-left: 1px solid #E8E9EC;
    padding-bottom: 20px;
}
.Fince_Cw_B .hhhh {
  overflow: hidden;
    margin: 20px 0 0 20px;
}
.Fince_Cw_B .Blo_a {
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
    padding-left: 10px;
    margin-bottom: 15px;
}
.Fince_Cw_B .Blo_a .Blo_a3 {
    width: 16px;
    height: 16px;
    float: right;
    margin: 7px 0 0 10px;
    background: #0081FF;
}
.Fince_Cw_B .Blo_a .Blo_a4 {
    float: right;
    margin: 5px 0 0 0px;
}
.Fince_Cw_B .Blacks {
    display: flex;
    margin: 10px 0 0 22px;
}
.Fince_Cw_B .Blo_a1 {
    width: 28px;
    height: 28px;
    border-radius: 100px;
    float: left;
}
.Fince_Cw_B .Blo_a2 {
    float: left;
    margin: 5px 0 0 11px;
}
.Fince_Cw_B .hhhh {
  overflow: hidden;
  margin: 20px 0 0 20px;
}
.Fince_Cw_B .hhhh .el-input {
  width: 370px;
  float: left;
}
.Info_M .hhhh .el-input__inner, .el-textarea__inner {
  width: 370px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.Fince_Cw_B .hhhh .el_seso {
  float: left;
  margin-top: 4px;
}
.Fince_Cw_B .Blo_b {
    margin-left: 12px;
    margin-top: 15px;
}
.Fince_Cw_B .Blo_b .el-checkbox__input {
    float: left;
    margin-top: 9px;
}
.Info_M .Blo_b span {
    float: left;
    margin-top: 5px;
}
.Fince_Cw_B .Blo_b img {
    float: left;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    margin-right: 8px;
}
.Fince_Cw_B .Blo_b span {
    float: left;
    margin-top: 5px;
}
.tyhh {
    float: left;
    width: 100%;
    margin-top: 15px;
}
.Fince_Cw_B .tyhh img {
    float: left;
    width: 24px;
    height: 24px;
    background: #CCCCCC;
    border-radius: 50%;
}
.Fince_Cw_B .tyhh span {
    float: left;
    margin: 2px 0 0 10px;
}
.Fince_Cw_B .tyhh i {
    padding-top: 8px!important;
}
.Fince_Cw_B .Dityo {
    margin: 0;
    padding: 0;
    margin-top: 25px;
    clear: both;
}
.Fince_Cw_B .Tiii .el-input__inner, .el-textarea__inner{
  width: 220px;
  padding-left: 30px;
}
.Fince_Cw_B .Tiii .el-input {
  width: 220px;
}
.Fince_Cw_B .Tiii span {
  margin: 0;
}
.Fince_Cw_B .Ruuu {
  margin-top: 30px;
  margin-bottom: 15px;
}
.Ryyy .el-input__inner, .Ryyy .el-textarea__inner{
  height: 100px!important;
}
.Fince_Cw_B .uiius {
  display: inline-block;
  padding: 0 10px;
  height: 25px;
  line-height: 25px;
  background: #fff;
  float: left;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #262626;
  margin-top: 2px;
  cursor: pointer;
}
.Fince_Cw_B .sddsdd {
  background: #0081ff;
  color: #fff!important;
  display: inline-block;
  padding: 4px 12px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  border-radius: 5px;
  cursor: pointer;
}
.Fince_Cw_B .Tatt_a {
  width: 315px;
  height: 34px;
  background: #1890FF;
  border-radius: 16px;
  margin-bottom: 30px;
}
.Fince_Cw_B .Tatt_a span {
  display: inline-block;
  text-align: center;
  width: 102px;
  height: 28px;
  line-height: 30px;
  color: #fff;
  /* background: #FFFFFF; */
  border-radius: 14px;
    margin-top: 3px;
  margin-left: 2px;
  cursor: pointer;
}
.Fince_Cw_B .Rig_Ddddd {
  
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    width: 117px;
    float: left;
    text-align: right;
    margin-top: 11px;
    margin-right: 13px;

}
.Fince_Cw_B .Rttyyy {
  float: left;
}
.Fince_Cw_B .actyty {
  background: #fff;
  color: #1890FF!important;
}
.Fince_Cw_B .Terer .el-input__inner, .el-textarea__inner{
    width: 420px;
    height: 32px;
    line-height: 32px;
}
.Fince_Cw_B .uyuyyu {
  width: 594px;
  height: 79px;
  background: #FAFAFA;
  border-radius: 4px;
  padding: 20px;
  box-sizing: border-box;
font-size: 14px;
font-family: PingFang SC;
font-weight: 500;
color: #595959;
}


.Fince_Cw_B .sadasd table td{
  background:#FFF;
  padding-left: 10px;
  height: 36px;
  font-size: 14px;
font-family: PingFang SC;
font-weight: 500;
color: #595959;
  } 
 .Fince_Cw_B .Rtt_im22g {
   width: auto;
   height: 150px;
   margin-right: 20px;
  }
</style>
